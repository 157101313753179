<template>
	<div class="add-role-form">
		<p class="error-message" v-if="errorMessage">{{ computedErrorMessage }}</p>
		<p v-if="!isEditRoles" class="show-form toggle-button dark" :class="{active: isEditRoleRoute}" data-container="toggle-1" v-on:click="toggleForm()">
			<span class="add"><i class="fas fa-plus-circle"></i></span> Add a role
		</p>
		<div class="toggle-container" :class="{active: isEditRoleRoute || isEditRoles}" v-if="isEditRoleRoute || isEditRoles" id="toggle-1">
			<form id="add-role-form" class="dark" novalidate>
				<div class="form-row">
					<input type="text" id="rolename" v-model="roleName" :class="{error: v$.$error}" placeholder="Role name" :disabled="!can(getAuthData, 'update_all_role_name')" v-if="can(getAuthData, 'read_all_role_name')" required />
					<IconErrors v-if="v$.$error" :errors="v$.$errors" />
				</div>
				<!-- Temporarily removed as it has no functionality -->
				<!-- <div class="checkbox" v-if="can(getAuthData, 'read_all_role_isGlobalRole')">
					<label class="align-left">Is this a global role?
						<div class="checkbox-wrapper circle-checkbox">
							<input :checked="isGlobalRole" :disabled="isGlobalRoleDisabled || !can(getAuthData, 'update_all_role_isGlobalRole')" @click="handleIsGlobalCheckbox($event)" type="checkbox" value="isGlobalRole" />
							<span class="circle"></span>
						</div>
					</label>
				</div> -->
				<div class="form-row two-columns permissions" v-if="can(getAuthData, 'read_all_role_permissions')">
					<label>Permissions</label>
					<div class="permissions">
						<div class="permissions__action-group" v-for="(actionGroup, action) in sortedPermissions" :key="actionGroup[0].name">
							<div class="heading">
								<div class="action-group">
									<p class="action-group__title">{{ action }}</p>
								</div>
							</div>
							<div class="permissions__action-group__check">
								<div class="permissions grid-container">
									<div v-for="permission in actionGroup" :key="permission.value" class="checkbox tooltip" :class="{'label-display-none': !permission.checked && !permission.enabled}">
										<div class="tooltiptext">
											<span>Depends on:</span>
											<p v-for="dependency in permission.dependsOn" :key="dependency">{{ dependency }}</p>
										</div>
										<label :for="permission.value">
											{{ permission.value }}
											<div class="checkbox-wrapper circle-checkbox">
												<input @click="handlePermissionClick($event)" type="checkbox" :checked="permission.checked" :disabled="!permission.enabled" :value="permission.value" :id="permission.value" />
												<span class="circle"></span>
											</div>
											<!-- end checkbox-wrapper -->
										</label>
									</div>
								</div>
								<!-- end permissions -->
							</div>
						</div>
					</div>
					<!-- end permissions -->
				</div>
				<!-- end form-row -->
				<button type="submit" class="save" @click.prevent="submitForm()">Save</button>
				<p class="error-message" v-if="formMessage">{{ formMessage }}</p>
				<Errors :errors="responseErrors" />
			</form>
		</div>
		<!-- end toggle-container -->
	</div>
	<!-- end add-role-form -->
</template>
<script>
//import { ref } from 'vue';
import permissionList from "../../services/permissionList"
import Errors from "./validation/Errors.vue"
import IconErrors from "./validation/iconErrors.vue"
import useValidate from "@vuelidate/core"
import {required} from "@vuelidate/validators"
import {mapGetters} from "vuex"
export default {
	data() {
		return {
			v$: useValidate(),
			isEditRoleRoute: this.$route.name === "Edit Roles" ? true : false,
			isGlobalRole: true,
			isGlobalRoleDisabled: false,
			sortedPermissions: {},
			errorMessage: "",
			rolePermissions: [],
			formMessage: "",
			permissions: permissionList.globalPermissions,
		}
	},
	created() {
		if (this.isEditRoleRoute) {
			this.populateCheckBoxes()
		}
	},
	validations: {
		roleName: {
			required: required,
		},
	},
	components: {
		Errors,
		IconErrors,
	},
	methods: {
		sortPermissions() {
			this.sortedPermissions = {}
			for (let permission of this.permissions) {
				let permissionAction = permission.value.split("_")[0]
				this.sortedPermissions[permissionAction] = this.sortedPermissions[permissionAction] ? [...this.sortedPermissions[permissionAction], permission] : [permission]
			}
		},
		async populateCheckBoxes() {
			let fetchedPerms = await this.$store.getters["roles/getRolePermissions"]
			this.rolePermissions = [...fetchedPerms]

			// Set the checkboxes for the permissions
			for (let permission of this.permissions) {
				// If the role hase the permission, then check the box
				if (this.rolePermissions.includes(permission.value)) {
					permission.checked = true
				}

				// Check the dependancies of all the boxes
				this.findPermissionDependancies(permission)

				// If the role can do_everything then check & disable all boxes
				if (this.rolePermissions.includes("do_everything")) {
					permission.checked = true
					permission.enabled = false
				}

				if (!this.can(this.getAuthData, "update_all_role_permissions")) {
					permission.enabled = false
				}
			}

			if (this.rolePermissions.includes("do_everything")) {
				this.isGlobalRole = true
				this.isGlobalRoleDisabled = true
			} else {
				this.isGlobalRole = await this.$store.getters["roles/getIsGlobalRole"]
			}

			this.sortPermissions()
		},
		findPermissionDependancies(permission) {
			// Guard clauses
			// Exit if permisison has no dependancies
			if (!permission.dependsOn) return
			if (!permission.dependsOn.length > 0) return

			// Checks every item in array against target

			this.disableIfDependencyNotChecked(permission.dependsOn, this.rolePermissions, permission)
		},
		disableIfDependencyNotChecked(dependencies, rolePermissions, permission) {
			let checker = (arr, target) => target.every((v) => arr.includes(v))
			// If all the dependencies are in the role's permissions
			if (checker(rolePermissions, dependencies)) {
				return (permission.enabled = true)
			}
			// Uncheck the checkbox
			permission.checked = false
			// Disable the checkbox
			permission.enabled = false
			// If the dependant checkbox's value is in the role permissinos
			let isPermInPermissions = this.rolePermissions.includes(permission.value)
			if (isPermInPermissions) {
				const indexOfCheckValue = this.rolePermissions.indexOf(permission.value)
				const value = [...this.rolePermissions]
				value.splice(indexOfCheckValue, 1)
				this.rolePermissions = [...value]
				// Remove the dependant checkbox value
				this.$store.commit("roles/updateRoleData", {target: "permissions", value})
			}
		},
		handlePermissionClick($event) {
			// Is the checbkox checked or unchecked?
			const isChecked = $event.target.checked,
				// What is the value of the checkbox
				checkVal = $event.target.value,
				target = "permissions",
				// Check if the checked value already exists in the permissions
				isValueInPermissions = this.rolePermissions.includes(checkVal),
				// Spread the currentPermsisions into a value array for later
				value = [...this.rolePermissions]

			// If the checkbox is checked
			// AND the value isn't already in the permissions
			if (isChecked && !isValueInPermissions) {
				// Add the value to the value array
				value.push(checkVal)
			}

			// If the chexcbox is unchecked
			// AND the value is in the permissions array
			if (!isChecked && isValueInPermissions) {
				// Find the index of the value & remove it from the value array
				const indexOfCheckValue = value.indexOf(checkVal)
				value.splice(indexOfCheckValue, 1)
			}

			// Commit the value array to update role data permissions
			this.$store.commit("roles/updateRoleData", {target, value})
			this.populateCheckBoxes()
		},
		toggleForm() {
			this.isEditRoleRoute = !this.isEditRoleRoute
		},
		async submitForm() {
			this.v$.$validate()

			if (this.v$.$errors.length > 0) {
				return (this.responseErrors = [{$message: "Form has errors, please check and try again."}])
			} else {
				if (this.isEditRoles) {
					const response = await this.$store.dispatch("roles/updateRole")
					//console.log(response)
					this.formMessage = response.message
				} else {
					await this.$store.dispatch("roles/createRole")
					this.$emit("roleCreated")
				}
				this.responseErrors = [{$message: ""}]
			}
		},
		handleIsGlobalCheckbox($event) {
			// Is the checbkox checked or unchecked?
			const isChecked = $event.target.checked
			const target = "isGlobalRole"
			const value = isChecked
			this.isGlobalRole = isChecked
			// Commit the value array to update role data permissions
			this.$store.commit("roles/updateRoleData", {target, value})
			this.populateCheckBoxes()
		},
	},
	computed: {
		isEditRoles() {
			return this.$route.name === "Edit Role" || this.$route.name === "Edit Roles"
		},
		formValid() {
			const {length} = this
			return +length > 0
		},
		computedErrorMessage() {
			if (this.errorMessage.length > 0) {
				return this.errorMessage
			}
			return ""
		},
		roleName: {
			get() {
				return this.$store.getters["roles/getRoleName"] || ""
			},
			set(value) {
				let target = "name"
				this.$store.commit("roles/updateRoleData", {target, value})
			},
		},
		...mapGetters("auth", ["getAuthData"]),
	},
}
</script>
<style>
/* Tooltip container */
.tooltip {
	position: relative;
	display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
	visibility: hidden;
	width: auto;
	background-color: black;
	color: #fff;
	text-align: center;
	padding: 10px;
	border-radius: 6px;
	opacity: 0;
	transition: 0.3s;

	/* Position the tooltip text - see examples below! */
	left: 100%;
	position: absolute;
	z-index: 1;
	transform: translateX(20px);
}

.tooltip .tooltiptext p {
	margin-bottom: 5px;
}

.tooltip .tooltiptext span {
	margin-bottom: 15px;
	font-weight: 800;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
	transform: translateX(25px);
}
</style>
